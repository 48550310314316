import { Component, Inject, OnInit } from "@angular/core";
import { CrmService } from "../../../../servicios/crm.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UsuariosComponent } from "../../../usuarios/usuarios.component";
import { Direccion } from "../../../../modelos/Direccion";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

type ObjetoCoordenadas = {
  latitud: number;
  longitud: number;
};

@Component({
  selector: "app-ubicacion-direccion",
  templateUrl: "./ubicacion-direccion.component.html",
  styleUrls: ["./ubicacion-direccion.component.css"],
})
export class UbicacionDireccionComponent implements OnInit {
  public urlMaps: string = "";
  public direccion: Direccion;
  public guardandoDatos = false;

  constructor(
    public crmservice: CrmService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UsuariosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer
  ) {
    this.direccion = this.data.direccion;
    this.urlMaps = this.crmservice.urlMaps;
  }

  async ngOnInit() {
    if (!this.direccion.lat || !this.direccion.lng) {
      const coordenadas = await this.extraerCoordenadas();
      if (coordenadas) {
        this.direccion.lat = coordenadas.latitud;
        this.direccion.lng = coordenadas.longitud;
      } else {
        this.direccion.lat = null;
        this.direccion.lng = null;
      }
    }
  }

  public urlMapa(): SafeResourceUrl {
    let url: string;

    if (this.direccion.lat && this.direccion.lng) {
      url =
        this.urlMaps +
        this.direccion.lat +
        "+" +
        this.direccion.lng +
        "&language=es";

      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      if (!this.direccion.lat && !this.direccion.lng) {
        this.extraerCoordenadas().then((coordenadas) => {
          if (coordenadas) {
            this.direccion.lat = coordenadas.latitud;
            this.direccion.lng = coordenadas.longitud;
          }
        });
      }

      url =
        this.urlMaps +
        this.direccion.direccion.replace(/\s/g, "+") +
        "+" +
        this.direccion.poblacion.replace(/\s/g, "+") +
        "&language=es";

      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  public guardarDireccion(): void {
    this.guardandoDatos = true;
    this.crmservice.updateDireccion(this.direccion).subscribe(
      () => {
        this.guardandoDatos = false;
      },
      () => {
        this.guardandoDatos = false;
        this.snackBar.open("Error al actualizar la dirección", "OK", {
          duration: 2000,
        });
      },
      () => {
        this.guardandoDatos = false;
        this.snackBar.open("Dirección guardada correctamente", "OK", {
          duration: 2000,
        });
        // Actualizar la tabla de direcciones en caché.
        const pos = this.crmservice.direcciones.findIndex(
          (e) => e._id === this.direccion._id
        );
        if (pos !== -1) {
          this.crmservice.direcciones[pos].lat = this.direccion.lat;
          this.crmservice.direcciones[pos].lng = this.direccion.lng;
        }
      }
    );
  }

  async extraerCoordenadas(): Promise<ObjetoCoordenadas | null> {
    const url = this.urlMaps;
    const direccion =
      this.direccion.direccion.replace(/\s/g, "+") +
      "+" +
      this.direccion.poblacion.replace(/\s/g, "+");

    try {
      const htmlString = await fetch(url + direccion).then((response) =>
        response.text()
      );

      const regex = /\[\s*(\d+\.\d+)\s*,\s*(-?\d+\.\d+)\s*\]/;
      const coincidencia = htmlString.match(regex);

      if (coincidencia && coincidencia.length >= 3) {
        return {
          latitud: parseFloat(coincidencia[1]),
          longitud: parseFloat(coincidencia[2]),
        };
      }

      return null;
    } catch (error) {
      console.error("Error al extraer coordenadas:", error);
      return null;
    }
  }
}
