export module BBDD {
  export const BloquesFormacion: string[] = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "CARPINTERO DIGITAL",
  ];

  export const CursosCampus: string[] = ["1", "2", "3", "4", "5", "6"];

  export const BloquesCampus: string[] = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
  ];

  export const cursosBloquesCampus: { curso: string; bloques: string[] }[] = [
    {
      curso: "0. PROTOCOLO VENTA",
      bloques: ["1. PROTOCOLO VENTA"],
    },
    {
      curso: "1. MANEJO DEL 3D",
      bloques: [
        "1. APRENDE A UTILIZAR EL SIMULADOR",
        "2. CREACIÓN DE ESCENARIOS",
      ],
    },
    {
      curso: "2. REALIZA TU AMUEBLAMIENTO",
      bloques: ["1. CONCEPTOS PREVIOS ANTES DE AMUEBLAR", "2. BIBLIOTECAS"],
    },
    {
      curso: "3. AVANZADO",
      bloques: ["1. ILUMINACIÓN Y RENDERIZADO", "2. HERRAMIENTAS AVANZADAS"],
    },
    {
      curso: "4. CARPINTERÍA DIGITAL",
      bloques: ["1. TRABAJAR CON TABLEROS"],
    },
  ];

  export const Cargos: { nombre: string; id: string }[] = [
    { nombre: "Otro", id: "0" },
    { nombre: "Gerente", id: "1" },
    { nombre: "Contable", id: "2" },
    { nombre: "Vendedor", id: "3" },
    { nombre: "Encargado de tienda", id: "4" },
    { nombre: "Montador", id: "5" },
    { nombre: "Logística", id: "6" },
  ];

  export const Dias: string[] = [
    "Entre semana",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ];

  export const Equipos: { id: string }[] = [
    { id: "A" },
    { id: "B" },
    { id: "C" },
    { id: "D" },
    { id: "E" },
    { id: "F" },
    { id: "G" },
    { id: "H" },
    { id: "I" },
    { id: "J" },
    { id: "K" },
    { id: "L" },
    { id: "M" },
    { id: "N" },
    { id: "Ñ" },
    { id: "O" },
    { id: "P" },
    { id: "Q" },
    { id: "R" },
    { id: "S" },
    { id: "T" },
    { id: "U" },
    { id: "V" },
    { id: "W" },
    { id: "X" },
    { id: "Y" },
    { id: "Z" },
    { id: "0" },
    { id: "1" },
    { id: "2" },
    { id: "3" },
    { id: "4" },
    { id: "5" },
    { id: "6" },
    { id: "7" },
    { id: "8" },
    { id: "9" },
    { id: "P1" },
    { id: "P2" },
    { id: "P3" },
    { id: "P4" },
    { id: "P5" },
    { id: "P6" },
    { id: "P7" },
    { id: "P8" },
    { id: "P9" },
    { id: "P10" },
    { id: "P11" },
    { id: "P12" },
    { id: "P13" },
    { id: "P14" },
    { id: "P15" },
    { id: "P16" },
    { id: "P17" },
    { id: "P18" },
    { id: "P19" },
    { id: "P20" },
    { id: "P21" },
    { id: "P22" },
    { id: "P23" },
    { id: "P24" },
    { id: "P25" },
    { id: "P26" },
    { id: "P27" },
    { id: "P28" },
    { id: "P29" },
    { id: "P30" },
  ];

  export const Estados: { id: string; nombre: string }[] = [
    { id: "activo", nombre: "Activo" },
    { id: "noactivo", nombre: "No activo" },
    { id: "proscrito", nombre: "Proscrito" },
    { id: "candidato", nombre: "Candidato" },
    { id: "potencial", nombre: "Potencial" },
    { id: "activoI", nombre: "CreamosTuMueble (Físico)" },
    { id: "representante", nombre: "Representante" },
    { id: "potencialnoactivo", nombre: "Potencial no activo" },
  ];

  export const Hitos: { id: string; nombre: string }[] = [
    { nombre: "TCLA", id: "0" },
    { nombre: "CLASS", id: "1" }, // DESHABILITADO
    { nombre: "Wallviewer", id: "2" },
    { nombre: "CreamosTuMueble (Físico)", id: "3" },
    { nombre: "#YomequedoencasaAMUEBLANDO", id: "4" }, // DESHABILITADO
    { nombre: "Revista", id: "5" },
    { nombre: "3D", id: "6" },
    { nombre: "Campus Dorma", id: "13" },
    { nombre: "Idehábita (Virtual)", id: "7" },
    { nombre: "Muestras", id: "8" },
    { nombre: "Análisis", id: "9" },
    { nombre: "Colaborador #AMUEBLANDO", id: "10" }, // DESHABILITADO
    { nombre: "BannerCreamostumueble", id: "11" },
    { nombre: "Dosier presentación", id: "12" },
  ];

  export const Horas: { hora: string }[] = [
    { hora: "06:00" },
    { hora: "06:15" },
    { hora: "06:30" },
    { hora: "06:45" },
    { hora: "07:00" },
    { hora: "07:15" },
    { hora: "07:30" },
    { hora: "07:45" },
    { hora: "08:00" },
    { hora: "08:15" },
    { hora: "08:30" },
    { hora: "08:45" },
    { hora: "09:00" },
    { hora: "09:15" },
    { hora: "09:30" },
    { hora: "09:45" },
    { hora: "10:00" },
    { hora: "10:15" },
    { hora: "10:30" },
    { hora: "10:45" },
    { hora: "11:00" },
    { hora: "11:15" },
    { hora: "11:30" },
    { hora: "11:45" },
    { hora: "12:00" },
    { hora: "12:15" },
    { hora: "12:30" },
    { hora: "12:45" },
    { hora: "13:00" },
    { hora: "13:15" },
    { hora: "13:30" },
    { hora: "13:45" },
    { hora: "14:00" },
    { hora: "14:15" },
    { hora: "14:30" },
    { hora: "14:45" },
    { hora: "15:00" },
    { hora: "15:15" },
    { hora: "15:30" },
    { hora: "15:45" },
    { hora: "16:00" },
    { hora: "16:15" },
    { hora: "16:30" },
    { hora: "16:45" },
    { hora: "17:00" },
    { hora: "17:15" },
    { hora: "17:30" },
    { hora: "17:45" },
    { hora: "18:00" },
    { hora: "18:15" },
    { hora: "18:30" },
    { hora: "18:45" },
    { hora: "19:00" },
    { hora: "19:15" },
    { hora: "19:30" },
    { hora: "19:45" },
    { hora: "20:00" },
    { hora: "20:15" },
    { hora: "20:30" },
    { hora: "20:45" },
    { hora: "21:00" },
    { hora: "21:15" },
    { hora: "21:30" },
    { hora: "21:45" },
    { hora: "22:00" },
    { hora: "22:15" },
    { hora: "22:30" },
    { hora: "22:45" },
    { hora: "23:00" },
    { hora: "23:15" },
    { hora: "23:30" },
    { hora: "23:45" },
    { hora: "00:00" },
  ];

  export const Medios: { id: string; nombre: string }[] = [
    { nombre: "Llamada saliente", id: "0" },
    { nombre: "Llamada entrante", id: "1" },
    { nombre: "Visita", id: "2" },
    { nombre: "Email saliente", id: "3" },
    { nombre: "Email entrante", id: "4" },
    { nombre: "Videollamada saliente", id: "5" },
    { nombre: "Videollamada entrante", id: "6" },
    { nombre: "Teamviewer", id: "7" },
    { nombre: "Centro de formación", id: "8" },
    { nombre: "Teleformación", id: "9" },
  ];

  export const MotivosPosponer: { id: string; nombre: string }[] = [
    { nombre: "Otro motivo", id: "0" },
    { nombre: "Cliente atareado", id: "1" },
    { nombre: "El cliente no estaba", id: "2" },
  ];

  export const Objetivos: { id: string; nombre: string }[] = [
    { nombre: "Instalar", id: "0" },
    { nombre: "Presentar", id: "1" },
    { nombre: "Incidencia", id: "3" },
    { nombre: "Campus: Matricular", id: "15" },
    { nombre: "Campus: Completar bloque", id: "16" },
    { nombre: "Campus: Completar curso", id: "17" },
    { nombre: "Formación Antigua", id: "4" },
    { nombre: "Entregar", id: "6" },
    { nombre: "Actualizar", id: "7" },
    { nombre: "Desinstalar/Retirar", id: "8" },
    { nombre: "Crear proyecto y presupuesto", id: "9" },
    { nombre: "Enviar proyecto a fábrica", id: "10" },
    { nombre: "Realizar contrato", id: "11" },
    { nombre: "Comprobación de consumo", id: "12" },
    { nombre: "Seguimiento", id: "13" },
    { nombre: "Actualizar perfil", id: "14" },
  ];

  export const ObjetivosDV: { id: string; nombre: string }[] = [
    { nombre: "Cita", id: "300" },
    { nombre: "Proyecto3D", id: "301" },
    { nombre: "Correcciones", id: "302" },
    { nombre: "Pedido Fábrica", id: "303" },
  ];

  export const ObjetivosCampusDorma: { id: string; nombre: string }[] = [
    { nombre: "Matricular", id: "400" },
    { nombre: "Completar", id: "401" },
    { nombre: "Desactivar", id: "402" },
  ];

  export const ObjetivosTienda: { id: string; nombre: string }[] = [
    { nombre: "Elaborar presupuesto", id: "100" },
    { nombre: "Finalizar venta", id: "101" },
    { nombre: "Atención postventa", id: "102" },
    { nombre: "Postventa completa", id: "103" },
    { nombre: "Aviso de entrega", id: "104" },
    { nombre: "Planificar entrega", id: "105" },
    { nombre: "Información al cliente", id: "106" },
    { nombre: "Limpieza de tienda", id: "107" },
    { nombre: "Llamar a oficina", id: "108" },
    { nombre: "Servicio", id: "109" },
    { nombre: "Escanear pedido o albarán", id: "110" },
    { nombre: "Apertura/Cierre de tienda", id: "111" },
    { nombre: "Consultar CRM Web", id: "112" },
    { nombre: "Finalizar pedido", id: "113" },
  ];

  export const OpcionesRankingMostrar: { id: number; opcion: string }[] = [
    { id: 0, opcion: "Mostrar Todos" },
    { id: 1, opcion: "Clasificados Autom." },
    { id: 2, opcion: "Clasificados Manual." },
  ];

  export const Provincias: { nombre: string; prefijo: string }[] = [
    { nombre: "Álava", prefijo: "01" },
    { nombre: "Albacete", prefijo: "02" },
    { nombre: "Alicante", prefijo: "03" },
    { nombre: "Almería", prefijo: "04" },
    { nombre: "Asturias", prefijo: "33" },
    { nombre: "Ávila", prefijo: "05" },
    { nombre: "Badajoz", prefijo: "06" },
    { nombre: "Baleares", prefijo: "07" },
    { nombre: "Barcelona", prefijo: "08" },
    { nombre: "Burgos", prefijo: "09" },
    { nombre: "Cáceres", prefijo: "10" },
    { nombre: "Cádiz", prefijo: "11" },
    { nombre: "Cantabria", prefijo: "39" },
    { nombre: "Castellón", prefijo: "12" },
    { nombre: "Ciudad Real", prefijo: "13" },
    { nombre: "Córdoba", prefijo: "14" },
    { nombre: "La Coruña", prefijo: "15" },
    { nombre: "Cuenca", prefijo: "16" },
    { nombre: "Gerona", prefijo: "17" },
    { nombre: "Gibraltar", prefijo: "00" },
    { nombre: "Granada", prefijo: "18" },
    { nombre: "Guadalajara", prefijo: "19" },
    { nombre: "Guipúzcoa", prefijo: "20" },
    { nombre: "Huelva", prefijo: "21" },
    { nombre: "Huesca", prefijo: "22" },
    { nombre: "Jaén", prefijo: "23" },
    { nombre: "León", prefijo: "24" },
    { nombre: "Lleida", prefijo: "25" },
    { nombre: "Lugo", prefijo: "27" },
    { nombre: "Madrid", prefijo: "28" },
    { nombre: "Málaga", prefijo: "29" },
    { nombre: "Murcia", prefijo: "30" },
    { nombre: "Navarra", prefijo: "31" },
    { nombre: "Orense", prefijo: "32" },
    { nombre: "Palencia", prefijo: "34" },
    { nombre: "Las Palmas", prefijo: "35" },
    { nombre: "Pontevedra", prefijo: "36" },
    { nombre: "La Rioja", prefijo: "26" },
    { nombre: "Salamanca", prefijo: "37" },
    { nombre: "Segovia", prefijo: "40" },
    { nombre: "Sevilla", prefijo: "41" },
    { nombre: "Soria", prefijo: "42" },
    { nombre: "Tarragona", prefijo: "43" },
    { nombre: "Santa Cruz de Tenerife", prefijo: "38" },
    { nombre: "Teruel", prefijo: "44" },
    { nombre: "Toledo", prefijo: "45" },
    { nombre: "Valencia", prefijo: "46" },
    { nombre: "Valladolid", prefijo: "47" },
    { nombre: "Vizcaya", prefijo: "48" },
    { nombre: "Zamora", prefijo: "49" },
    { nombre: "Zaragoza", prefijo: "50" },
    { nombre: "Ceuta", prefijo: "51" },
    { nombre: "Melilla", prefijo: "52" },
  ];

  export const ProvinciasEquipos: { equipo: string; provincias: string[] }[] = [
    { equipo: "A", provincias: ["Burgos", "Palencia", "Soria"] },
    { equipo: "B", provincias: [] },
    { equipo: "C", provincias: ["Alicante", "Almería", "Murcia"] },
    { equipo: "D", provincias: ["Jaén"] },
    { equipo: "E", provincias: ["Gerona", "Barcelona"] },
    { equipo: "F", provincias: ["Guipúzcua", "Vizcaya", "Cantabria", "Álava"] },
    { equipo: "G", provincias: ["Orense", "La Coruña", "Pontevedra", "Lugo"] },
    { equipo: "H", provincias: [] },
    { equipo: "I", provincias: [] },
    { equipo: "J", provincias: [] },
    { equipo: "K", provincias: ["Málaga"] },
    { equipo: "L", provincias: ["León", "Asturias"] },
    {
      equipo: "M",
      provincias: ["Cádiz", "Sevilla", "Huelva", "Ceuta", "Reino Unido"],
    },
    { equipo: "N", provincias: [] },
    { equipo: "Ñ", provincias: [] },
    { equipo: "O", provincias: [] },
    { equipo: "P", provincias: ["Toledo", "Madrid", "Cuenca"] },
    { equipo: "Q", provincias: [] },
    { equipo: "R", provincias: ["Badajoz", "Cáceres", "Madrid"] },
    { equipo: "S", provincias: ["Córdoba", "Ciudad Real", "Albacete"] },
    { equipo: "T", provincias: [] },
    { equipo: "U", provincias: [] },
    { equipo: "V", provincias: ["Castellón", "Valencia"] },
    { equipo: "W", provincias: [] },
    { equipo: "X", provincias: [] },
    { equipo: "Y", provincias: [] },
    { equipo: "Z", provincias: [] },
    {
      equipo: "0",
      provincias: [
        "Francia",
        "Lleida",
        "Navarra",
        "Baleares",
        "Barcelona",
        "La Rioja",
      ],
    },
    { equipo: "1", provincias: [] },
    { equipo: "2", provincias: [] },
    { equipo: "3", provincias: [] },
    { equipo: "4", provincias: [] },
    { equipo: "5", provincias: [] },
    {
      equipo: "6",
      provincias: [
        "Valladolid",
        "Zamora",
        "Salamanca",
        "Ávila",
        "Palencia",
        "Segovia",
        "Burgos",
      ],
    },
    { equipo: "7", provincias: ["Huesca", "Teruel"] },
    { equipo: "8", provincias: ["Baleares"] },
    { equipo: "9", provincias: ["Almería", "Granada"] },
    { equipo: "P1", provincias: [] },
    { equipo: "P2", provincias: [] },
    { equipo: "P3", provincias: [] },
    { equipo: "P4", provincias: [] },
    { equipo: "P5", provincias: [] },
    { equipo: "P6", provincias: [] },
    { equipo: "P7", provincias: [] },
    { equipo: "P8", provincias: [] },
    { equipo: "P9", provincias: [] },
    { equipo: "P10", provincias: [] },
    { equipo: "P11", provincias: [] },
    { equipo: "P12", provincias: [] },
    { equipo: "P13", provincias: [] },
    { equipo: "P14", provincias: [] },
    { equipo: "P15", provincias: [] },
    { equipo: "P16", provincias: [] },
    { equipo: "P17", provincias: [] },
    { equipo: "P18", provincias: [] },
    { equipo: "P19", provincias: [] },
    { equipo: "P20", provincias: [] },
    { equipo: "P21", provincias: [] },
    { equipo: "P22", provincias: [] },
    { equipo: "P23", provincias: [] },
    { equipo: "P24", provincias: [] },
    { equipo: "P25", provincias: [] },
    { equipo: "P26", provincias: [] },
    { equipo: "P27", provincias: [] },
    { equipo: "P28", provincias: [] },
    { equipo: "P29", provincias: [] },
    { equipo: "P30", provincias: [] },
  ];

  export const Resultados: { id: string; nombre: string }[] = [
    { nombre: "Sin finalizar", id: "-1" },
    { nombre: "Positivo", id: "0" },
    { nombre: "No le ha gustado", id: "1" },
    { nombre: "Quiere catálogo papel, no digital", id: "2" },
    { nombre: "No tiene ordenador", id: "3" },
    { nombre: "Es muy tradicional", id: "4" },
    { nombre: "Se le figura caro", id: "5" },
    { nombre: "Decisión propia del comercial", id: "6" },
    { nombre: "Tienda cerrada permanentemente", id: "7" },
    { nombre: "Hace lo mismo que nosostros", id: "8" },
  ];

  export const Roles: { id: string; nombre: string }[] = [
    { id: "admin", nombre: "Administrador" },
    { id: "supervisor", nombre: "Supervisor" },
    { id: "comercial", nombre: "Comercial" },
    { id: "asesor", nombre: "Asesor" },
    { id: "pedidos", nombre: "Pedidos" },
    { id: "distribuidor", nombre: "Distribuidor" },
    { id: "influencer", nombre: "Influencer" },
  ];

  export const TiposCliente: { id: string; nombre: string }[] = [
    { id: "distribuidor", nombre: "Distribuidor" },
    { id: "prescriptor", nombre: "Prescriptor" },
  ];

  export const CategoriasCliente: { id: string; nombre: string }[] = [
    { id: "tienda", nombre: "Tienda" },
    { id: "arquitecto", nombre: "Arquitecto" },
    { id: "interiorista", nombre: "Interiorista" },
    { id: "decorador", nombre: "Decorador" },
    { id: "cadenaHotelera", nombre: "Cadena Hotelera" },
    { id: "constructor,", nombre: "Constructor" },
    { id: "tiendaDeDescanso,", nombre: "Tienda de Descanso" },
    { id: "tiendaOnline,", nombre: "Tienda Online" },
    { id: "agenciaInmobiliaria,", nombre: "Agencia Inmobiliaria" },
    { id: "carpintero,", nombre: "Carpintero" },
    { id: "montador,", nombre: "Montador" },
  ];

  export const TiposDir: { id: string; nombre: string }[] = [
    { id: "0", nombre: "Comercial" },
    { id: "1", nombre: "De envío" },
    { id: "2", nombre: "De factura" },
    { id: "3", nombre: "Fiscal" },
    { id: "4", nombre: "Correspondencia" },
    { id: "5", nombre: "Almacén" },
    { id: "6", nombre: "Tienda" },
    { id: "7", nombre: "Virtual" },
    { id: "8", nombre: "Gancho" },
  ];

  export const TiposDirNCli: {}[] = [
    { id: "0", nombre: "Comercial" },
    { id: "6", nombre: "Tienda" },
    { id: "7", nombre: "Virtual" },
  ];

  export const TiposMuestras: {
    id: string;
    nombre: string;
    nombreCompleto: string;
  }[] = [
    {
      id: "pequeno",
      nombre: "Pequeño",
      nombreCompleto: "Caja de muestras pequeñas",
    },
    {
      id: "grande",
      nombre: "Grande",
      nombreCompleto: "Caja de muestras grandes",
    },
    { id: "lacado", nombre: "Lacado", nombreCompleto: "Muestras lacadas" },
  ];

  export const Tratamientos: { id: string; nombre: string }[] = [
    { id: "Sr.", nombre: "Señor" },
    { id: "Sra.", nombre: "Señora" },
    { id: "Srta.", nombre: "Señorita" },
    { id: "Tutear", nombre: "Tutear" },
  ];

  export const Vias: string[] = [
    "calle",
    "plaza",
    "paseo",
    "avenida",
    "camino",
    "carretera",
    "otro",
  ];
}
