<div class="cabecera-modal">
  <mat-icon class="icon-modal">beenhere</mat-icon>
  <strong>UBICACIÓN</strong>
  <mat-icon class="cerrar-modal" [mat-dialog-close]>close</mat-icon>
</div>

<div class="content-modal">
  <div *ngIf="!guardandoDatos" class="m-4">
    <div class="row">
      <div class="col-12">
        <iframe
          width="100%"
          height="600"
          class="border border-light rounded"
          [src]="urlMapa()"
          allowfullscreen
        ></iframe>
      </div>
      <div class="col-sm-6 col-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Latitud</mat-label>
          <label class="w-100 m-0">
            <input matInput [(ngModel)]="direccion.lat" placeholder="Latitud" />
          </label>
        </mat-form-field>
      </div>
      <div class="col-sm-6 col-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Longitud</mat-label>
          <label class="w-100 m-0">
            <input
              matInput
              [(ngModel)]="direccion.lng"
              placeholder="Longitud"
            />
          </label>
        </mat-form-field>
      </div>
      <div class="col-12 text-end">
        <button
          (click)="guardarDireccion()"
          color="primary"
          mat-raised-button
          [disabled]="!direccion.lat || !direccion.lng"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="guardandoDatos" class="spinner-container">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
